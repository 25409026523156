"use client";
import Link from "next/link";
import { AppStore } from "@/components/icons/AppStore";
import { PlayStore } from "@/components/icons/PlayStore";
import { UniqueSellingPoints } from "@/components/unique-selling-points";
import { ArrowDown } from "react-feather";
import { useRef } from "react";

export const Landing = () => {
	const uspRef = useRef<HTMLDivElement | null>(null);
	const onScrollPress = () => {
		if (uspRef.current) {
			uspRef.current.scrollIntoView({ behavior: "smooth" });
		}
	};

	return (
		<div className="">
			<div className="landing">
				<div className="landing__background" />

				<div className="landing__content">
					<div className="landing__title">
						<h1>Unlock your full potential</h1>
					</div>

					<div className="landing__subtitle">
						<h3>You don&#39;t have to do it alone</h3>
						<h3>So why would you?</h3>
					</div>
				</div>

				<div className="banners ">
					<div className="banner">
						<Link href="https://apps.apple.com/us/app/apollofit/id1668675980">
							<AppStore width={160} />
						</Link>
					</div>

					<div className="banner">
						<Link href="https://play.google.com/store/apps/details?id=com.apollofit.apollo&pcampaignid=web_share">
							<PlayStore width={160} />
						</Link>
					</div>

					<div className="landing__scroll" onClick={onScrollPress}>
						<ArrowDown color="#fff" />
					</div>
				</div>
			</div>
			<UniqueSellingPoints divRef={uspRef} />
		</div>
	);
};
